.image-container{
  border-radius: 15px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin: 5%;
  position: relative;
  overflow-x: auto;
  z-index: 1; /*9999;*/

}

.image-inner-container {
  width: 100%;
  margin: 0 auto;
  max-width: 250px;
}

#projectImg{
    height: 255px;
    width: 320px;
    border-radius: 35px !important;
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */{
  #projectImg{
    height: 225px;
    width: 260px;
    border-radius: 35px !important;
    padding: 5px;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  #projectImg{
    height: 225px;
    width: 350px;
    border-radius: 35px !important;
    padding: 5px;
  }
}
@media only screen and (min-width: 64.01em) and (max-width: 89em)/* min-width 1025px and max-width 1440px, use when QAing tablet-only issues */
{
  #projectImg{
    height: 255px;
    width: 370px;
    border-radius: 35px !important;
    padding: 5px;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1440px, xlarge screens */
{
  #projectImg{
    height: 295px;
    width: 445px;
    border-radius: 35px !important;
    border-bottom-right-radius: 35px !important;
    padding: 5px;
  }
}
@media only screen and (min-width: 113.063em) /* min-width 1900px, xlarge screens */
{
  #projectImg{
    height: 335px;
    width: 565px;
    border-radius: 35px !important;
    border-bottom-right-radius: 35px !important;
    padding: 5px;
  }
}
@media (min-width: 100px) and (max-width: 570px){
  #projectImg{
    height: 225px;
    width: 260px;
    border-radius: 25px !important;
    margin: 5px;
    padding: 5px;
  }
}

/*project index*/
.flex-container{
  border-radius: 15px;
  padding: 0%;
  margin-left: 12%;
  margin-right: 12%;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  z-index: 2;
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .flex-container{
    border-radius: 15px;
    padding: 0%;
    margin-left: 14%;
    margin-right: 14%;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    z-index: 2;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .flex-container{
    border-radius: 15px;
    padding: 0%;
    margin-left: 14%;
    margin-right: 14%;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    z-index: 2;
  }
}


/*Project Image Prop - wobble thumbail*/
.image {
  display: block;
  margin: 0 auto;
  /*width: calc(50% - 100px);*/
  width: calc(100%);
  fill-opacity: initial;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  /*background-color: rgb(88, 91, 248);*/
  border-radius: 25px;
  position: relative;
  overflow-y: hidden;
  z-index: 9; /*9999;*/
  overflow-x: auto;
}

.image:focus, .image:hover {
  animation: wobble 1s 1;
}

@keyframes wobble {
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
