

.main-name {
  color: #3b5998;

}

label {
font-weight: bold;
width: 150px;
float: left;
}
