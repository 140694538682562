.image-container{
  border-radius: 15px;
  /*background-color: white;*/
  padding: 5%;
  margin: 3%;
  position: relative;
  overflow-y: auto;
  z-index: 1; /*9999;*/
}

/*project index*/
.flex-container{
  border-radius: 15px;
  padding: 1%;
  margin-top: 12%;
  margin-right: 12%;
  position: relative;
  overflow-y: auto;
  z-index: 2;
}

/*
.flex-container{
  border-radius: 15px;
  padding: 15%;
  margin: 6%;
  position: relative;
  overflow-y: auto;
  z-index: 3;
}
*/
