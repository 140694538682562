
/* ------------ */
/* Project section */
/* ------------ */
.homeProject{
  position: relative;
  overflow-y: auto;
  z-index: 1; /*9999;*/
  text-align: center;
  padding-top: 0% !important;
  margin-top: 0% !important;
  margin-bottom: 2% !important;
  transform: translateY(-4%);
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .homeProject {
    position: relative;
    overflow-y: auto;
    z-index: 1; /*9999;*/
    text-align: center;
    padding-top: 0% !important;
    margin-top: 0% !important;
    margin-bottom: 0% !important;
    transform: translateY(0%);
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .homeProject {
    position: relative;
    overflow-y: auto;
    z-index: 1; /*9999;*/
    text-align: center;
    padding-top: 0% !important;
    margin-top: 0% !important;
    margin-bottom: 0% !important;
    transform: translateY(0%);
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .homeProject {
    position: relative;
    overflow-y: auto;
    z-index: 1; /*9999;*/
    text-align: center;
    padding-top: 0% !important;
    margin-top: 0% !important;
    margin-bottom: 0% !important;
    transform: translateY(0%);
  }
}

/* ------------ */
/* Education section */
/* ------------ */
.section3_header {
  font-size: 2.5em !important;
  text-align: center;
  justify-content: center;
  padding-top: 2% !important;
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .section3_header {
    font-size: 1.5em !important;
    text-align: center;
    justify-content: center;
    padding-top: 2% !important;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .section3_header {
    font-size: 2.5em !important;
    text-align: center;
    justify-content: center;
    padding-top: 2% !important;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .section3_header {
    font-size: 2.5em !important;
    text-align: center;
    justify-content: center;
    padding-top: 2% !important;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .section3_header {
    font-size: 3.5em !important;
    text-align: center;
    justify-content: center;
    padding-top: 2% !important;
  }
}


.section3_EDU{
  width: 100vw;
  /*height: 550px; */
  position: fixed;
  /* top: calc(550px * 1); */
  z-index: 2;
  background-color: #EAC79C;
  transform: translateY(-15%);
  margin-bottom: 2%;
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */

{
  .section3_EDU {
    width: 100vw;
    /*height: 550px; */
    position: fixed;
    /* top: calc(550px * 1); */
    z-index: 2;
    background-color: #EAC79C;
    transform: translateY(0%);
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .section3_EDU {
    width: 100vw;
    /*height: 550px; */
    position: fixed;
    /* top: calc(550px * 1); */
    z-index: 2;
    background-color: #EAC79C;
    transform: translateY(0%);
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .section3_EDU {
    width: 100vw;
    /*height: 550px; */
    position: fixed;
    /* top: calc(550px * 1); */
    z-index: 2;
    background-color: #EAC79C;
    transform: translateY(0%);
    margin-bottom: 2%;
  }
}

.svg_curve_top{
  width: 50%;
  height: 7vw;
  position: absolute;
  transform: translateY(-98%);
}

.section3_container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

/*
.section3_container{
  background-color: #EAC79C;
  position: relative;
  overflow-y: auto;
  z-index: 7;
  text-align: center;
  padding-top: 18px !important;
  margin-top: 8% !important;
}
*/

.svg_curve_bottom{
  width: 50%;
  height: 12vw;
  position: absolute;
  transform: translateY(-1%);

}

/* ------------ */
/* Home section */
/* ------------ */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 0px !important;
  justify-content: center;
  text-align: center;
}

.home-section {
  position: fixed;
  z-index: 88;
  height: 100%;
  padding-top: 20%;
  margin-top: 0%;
  background-color: #A8D0E6;
  margin-bottom: 0% !important;
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .home-section {
    position: fixed;
    z-index: 88;
    height: 100%;
    padding-top: 63%;
    margin-top: 0%;
    background-color: #A8D0E6;
    margin-bottom: 0% !important;
  }
}

@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .home-section {
    position: fixed;
    z-index: 88;
    height: 100%;
    padding-top: 19%;
    margin-top: 0%;
    background-color: #A8D0E6;
    margin-bottom: 0% !important;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .home-section {
    position: fixed;
    z-index: 88;
    height: 100%;
    padding-top: 19%;
    margin-top: 0%;
    background-color: #A8D0E6;
    margin-bottom: 0% !important;
  }
}

/*
.home-contact{
  position: fixed;
  z-index: 99;
  background-color: #A8D0E6;
  transform: translateY(100%);

}
@media (max-width: 767px) {
  .home-contact {
    position: fixed;
    z-index: 99;
    background-color: #A8D0E6;
    transform: translateY(170%);
  }
}
*/

.container-fluid{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  padding-bottom: 10%;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  z-index: 3; /*9999;*/
  cursor: pointer;
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .container-fluid{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    height: 100%;
    padding-bottom: 20%;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    z-index: 3; /*9999;*/
    cursor: pointer;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .container-fluid{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    height: 100%;
    padding-bottom: 16%;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    z-index: 3; /*9999;*/
    cursor: pointer;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .container-fluid{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    height: 100%;
    padding-bottom: 16%;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    z-index: 3; /*9999;*/
    cursor: pointer;
  }
}

.tent {
  color: black;
  text-align: 4%;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .heading {
    font-size: 2.0em !important;
}
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .heading {
    font-size: 3.5em !important;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .heading {
    font-size: 4.0em !important;
  }
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .heading-name {
    font-size: 2.0em !important;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .heading-name {
    font-size: 3.5em !important;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .heading-name {
    font-size: 4.0em !important;
  }
}

.main-name {
  color: #3b5998;
  /*box-shadow: 10px 10px 10px 0px black;*/
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .section_name {
    color: #3b5998 !important;
    font-size: 1.5em !important;
    /*box-shadow: 10px 10px 10px 0px black;*/
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .section_name {
    color: #3b5998 !important;
    font-size: 1.5em !important;
    /*box-shadow: 10px 10px 10px 0px black;*/
  }
}

/* ------------ */
/* Form section */
/* ------------ */
.form-name {
  font-size: 1.6em !important;
  text-align: center;
  padding-bottom: 10px !important;
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .form-name {
      font-size: 1.8em !important;
    }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .form-name {
      font-size: 2.0em !important;
    }
}


.form-container{
  position: relative;
  z-index: 99;
  background-color: #A8D0E6;
  transform: translateY(100%);
}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .form-container {
    position: relative;
    z-index: 99;
    background-color: #A8D0E6;
    transform: translateY(160%);
    padding-bottom: 45%;
    padding-top: 20%;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .form-container {
    position: relative;
    padding-top:2%;
    z-index: 99;
    background-color: #A8D0E6;
    transform: translateY(180%);
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .form-container {
    position: relative;
    padding-top:2%;
    z-index: 99;
    background-color: #A8D0E6;
    transform: translateY(180%);
  }
}

.form-content {
  color: #F8E9A1;
  text-align: left;
}

.form-header {
  padding-top: 50px !important;
  text-align: center;



}


/* ------------ */
/*typewriter*/
/* ------------ */
.Typewriter__wrapper {
  font-size: 2.0em !important;
  color: #F4976C !important;
  font-weight: 600 !important;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .Typewriter__wrapper {
    font-size: 2.0em !important;
    font-weight: 500 !important;
    position: absolute !important;
    font-family: 'Roboto', sans-serif;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .Typewriter__wrapper {
    font-size: 3.5em !important;
    color: #F4976C !important;
    font-weight: 600 !important;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .Typewriter__wrapper {
    font-size: 3.5em !important;
    color: #F4976C !important;
    font-weight: 600 !important;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }
}


.typewriter_wrap{
  text-align: center;
  padding-left: 40%;
  position: absolute !important;
  z-index: 1;
  padding-bottom: 50%;
  transform: translateY(-40%);

}
@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .typewriter_wrap {
    text-align: left;
    position: absolute !important;
    padding-bottom: 90%;
    padding-left: 20%;
    width: 100vw;
    z-index: 1;
    transform: translateY(-90%);
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .typewriter_wrap {
    text-align: left;
    position: absolute !important;
    padding-bottom: 0%;
    padding-left: 30%;
    width: 100vw;
    z-index: 1;
    transform: translateY(-150%);
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .typewriter_wrap {
    text-align: left;
    position: absolute !important;
    padding-bottom: 0%;
    padding-left: 38%;
    width: 100vw;
    z-index: 1;
    transform: translateY(-150%);
  }
}



.Typewriter__cursor {
  font-size: 2.0em !important;
  color: #3b5998 !important;
}

@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .Typewriter__cursor {
    font-size: 3.0em !important;
    color: #3b5998 !important;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .Typewriter__cursor {
    font-size: 3.0em !important;
    color: #3b5998 !important;
  }
}


/*Arrow*/
.downArrow_bounce{
  padding-top: 218px;
  text-align: center;
}

@media only screen and (max-width: 40em)/* max-width 640px, mobile-only styles, use when QAing mobile issues */
{
  .downArrow_bounce{
    padding-top: 220px;
    text-align: center;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em)/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
{
  .downArrow_bounce{
    padding-top: 380px;
    text-align: center;
  }
}
@media only screen and (min-width: 89.063em) /* min-width 1441px, xlarge screens */
{
  .downArrow_bounce{
    padding-top: 380px;
    text-align: center;
  }
}

.downArrow{
	position: relative;
	bottom: 45%;
}

.bounce {
	-moz-animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-250px);
    transform: translateY(-250px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-250px);
    transform: translateY(-250px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-250px);
    -ms-transform: translateY(-250px);
    -webkit-transform: translateY(-250px);
    transform: translateY(-250px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
