
.App {
  text-align: center;
  /*margin-top: 10%;    This is the reason edu display wrong*/
  padding-bottom: 1%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /*background-image: linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%);*/
  background: rgb(36,58,94);
  background: linear-gradient(90deg, rgba(36,58,94,1) 0%, rgba(168,208,230,1) 100%);
  /*background: rgb(36,48,94);
  background: linear-gradient(90deg, rgba(36,48,94,1) 0%, rgba(168,208,230,1) 100%);
    background-image: linear-gradient(to bottom, #7DE2FC 0%, #ffecd2 100%);*/
  /*background: rgb(36,48,94);
  background: linear-gradient(90deg, rgba(36,48,94,1) 27%, rgba(168,208,230,1) 100%);*/
  /*background-image: linear-gradient( 90deg, #24305E 10%, #A8D0E6 100%);
  background-attachment: scroll;*/
  font-family: Raleway;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  background-color: rgb(88, 91, 248);
}

    /* Style the content */
.items {
  background-color: #FFFFFF;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  float:center;
  border-radius: 25px;

}

.Thumbnails{
  /*background-color: white;*/
}


.sidebar{
  margin-left: 5%;
  padding-bottom: 5%;
  border-radius: 25px;
  width: 84%;
  position: fixed;
  overflow-y: auto;
  z-index: 2;

}

#aside {
border-radius:  0px;
padding-bottom: 5%;
background-color: rgb(88, 91, 248);
}
@media screen and (max-width: 558px) {
  #aside {

    width: 100%;
    -moz-transform: translateX(-0px);
    -webkit-transform: translateX(-00px);
    -ms-transform: translateX(-00px);
    -o-transform: translateX(-00px);
    transform: translateX(-0px);
    padding-top: 5%;
    padding-bottom: 30%;
    margin-top: 10%;
    margin-bottom: 20%;
    float: center;
  }
}

.info {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 14px;
  a {
    color: inherit;
  }

  .notice {
    margin-top: 1rem;
    font-weight: 100;
  }

  @media screen and (min-width: 980px) {
    font-size: 18px;
    .notice {
      display: none;
    }
  }
}

.link {
  color: #000;
  color-adjust: bold;
}
.link:hover {
   color: blueviolet;
}
.linkedIn{
  height: 5%;
  width: 15%;
  align-content: flex-start;
}

.page{
  padding: 5%;
  margin: 2%;
  border-radius: 25px;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  z-index: 1;
  background-color: rgb(88, 91, 248);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  list-style: none;
}
@media screen and (max-width: 558px) {
  .page {
    /*width: 50px;*/
    height: 15%;
    -moz-transform: translateX(00px);
    -webkit-transform: translateX(00px);
    -ms-transform: translateX(00px);
    -o-transform: translateX(00px);
    transform: translateX(-00px);
    /*padding-top: 4em;*/
    overflow-y: scroll;
    z-index: 1;
    padding-top: 15%;
    padding-bottom:20%;
  }
}

.page-container{
  padding: 1%;
  border-radius: 25px;
}


.column{
  padding: 1px;
  max-height:10px;
  -ms-flex-align: right;
}

.row::after {
  content: "";
  clear: both;
  /*display: table-cell;*/
  padding: 1px;
}

.center-body{
  border-radius: 25px;
  /*background-color: white;*/
  padding: 5%;
  margin: 5%;
  position: relative;
  overflow-y: auto;
  z-index: 3; /*9999;*/
}


.mainmedia {
  /*background-color: whitesmoke;*/
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  float:center;
  border-radius: 25px;

}

.icons
ul {
  overflow: auto;
  position: left;
}

ul li {
  list-style-type: none;
  padding-left: 4%;
  margin-bottom: 30px;
  float:left;
}

ul li a i {
  background: #205D7A;
  color: #fff;
  width: 100%;
  height: 100%;
  font-display: center;
  border-radius: 20px;
  font-size: 25px;
  text-align: center;
  margin-right: 15px;
  padding-top: 15%;
  padding: 5%

}

.btn{
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
  padding: 10px 15px !important;
  border: none;
  font-weight: 400;
}

.btn.btn-learn {
  color: #000;
  border: 1px solid #000;
  background: transparent !important;
  text-align: center;
  align-content: right;
  vertical-align: middle;

}

.btn.btn-learn:hover {
  background: blueviolet !important;
  border: 2px solid #000 !important; }
  @media screen and (max-width: 768px) {
    .btn {
      width: 100%; }
}
  @media screen and (min-width: 100px) {
    .btn {
      width: 70%; }
}

.resume{
  width: 60%;
  float: right;
  clear: both;
  margin-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
}


@media screen and (max-width: 558px) {
  .icons
  ul {

  width:100%;
  float: center;
  }

  ul li {
  position: relative;
  list-style-type: none;
  float: center;
  margin: 5%;
  padding: 2%;

  list-style: none;
  /*letter-spacing: 1px; */
  }
  ul li a i {
  background: #205D7A;
  /*width: 100%;
  height: 100%;
  font-display: center;
  border-radius: 20px;
  font-size: 25px;
  text-align: center;

  padding-top: 10%;
        text-decoration: none;
        position: absolute;
        padding: 10px 0;
        font-family: "Quicksand", Arial, sans-serif;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;*/ }
        ul li a:after {
          /*content: "";
          position: absolute;
          height: 1px;
          bottom: 7px;
          left: 0;
          right: 0;
          background-color: #2c98f0;
          visibility: hidden;
          -webkit-transform: scaleX(0);
          -moz-transform: scaleX(0);
          -ms-transform: scaleX(0);
          -o-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */ }
        ul li a:hover {
          /* text-decoration: none;
          color: black;*/  }
          ul li a:hover:after {
            /* visibility: visible;
            -webkit-transform: scaleX(1);
            -moz-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
            transform: scaleX(1); */ }
       ul li.active a {
        /*color: #2c98f0;*/ }
        ul li.active a:after {
          /* visibility: visible;
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          -o-transform: scaleX(1);
          transform: scaleX(1);*/ }

}
